
import { Component, Prop, Vue } from "vue-property-decorator";
import { IOwnerProperty } from "@/models/OwnerProperties";
import OwnerPropertyCard from "@/components/OwnerPropertyCard.vue";
import HousfyLink from "@/components/protected/HousfyLink.vue";
import HousfySvg from "@/components/ui/HousfySvg.vue";
import { HousfyAlertMessage } from "housfy-ui-lib";

@Component({
  name: "OwnerProperties",
  components: {
    OwnerPropertyCard,
    HousfyLink,
    HousfySvg,
    HousfyAlertMessage,
  },
})
export default class OwnerProperties extends Vue {
  @Prop({ type: Array, default: () => [] })
  properties!: IOwnerProperty[];
  @Prop({ type: Boolean, default: false })
  showAlert!: boolean;
  @Prop({ type: String, default: "" })
  alertMessage!: string;
  @Prop({ type: String, default: "" })
  status!: string;

  get propertiesLength(): number {
    return this.properties?.length || 0;
  }
}
